import { useState, useEffect } from 'react';
import axios from 'axios';
import PostCard from '../../common/PostCard';
import PostCardSkeleton from '../../common/PostCardSkeleton';

interface PostDetails {
    title: string;
    description: string;
    content: string;
    image_location: string;
}

interface NewsPost {
    id: string;
    slug: string;
    post_details: PostDetails;
    tag: string;
    created_at: any;
    author: string;
    featured: boolean
}

const PostsGrid = () => {
    const [loading, setLoading] = useState(true);
    const [posts, setPosts] = useState<NewsPost[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get(`https://api.robinhoodboechout.be/api/v1/posts`);
                setPosts(response.data);
                setLoading(false);
            } catch (error) {
                setError('Failed to fetch news posts');
                setLoading(false);
                console.error(error);
            }
        };

        fetchPosts();
    }, []);

    if (error) {
        return <div className='py-16 flex flex-col items-center'>Error: {error}</div>;
    }

    return (
        <section className='py-16 flex flex-col items-center'>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-10 pt-8'>
                {loading && (
                    <>
                        <PostCardSkeleton />
                        <PostCardSkeleton />
                        <PostCardSkeleton />
                        <PostCardSkeleton />
                    </>

                )}
                {posts.map(post => (
                    <PostCard
                        key={post.id}
                        title={post.post_details.title}
                        description={post.post_details.description}
                        createdAt={post.created_at}
                        slug={post.slug}
                        image={post.post_details.image_location}
                        tag={post.tag}
                        author={post.author}
                    />
                ))}
            </div>
        </section>
    );
};

export default PostsGrid;