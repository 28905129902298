import React from 'react';

const PostCardSkeleton: React.FC = () => {
    return (
        <div className='rounded overflow-hidden shadow-lg flex flex-col w-80 animate-pulse'>
            <div className='relative bg-gray-300 h-48 w-full'></div>
            <div className='px-6 py-4 mb-auto'>
                <div className='h-6 bg-gray-300 rounded w-3/4 mb-2'></div>
                <div className='h-4 bg-gray-300 rounded w-full mb-2'></div>
                <div className='h-4 bg-gray-300 rounded w-1/2'></div>
            </div>
            <div className='px-6 py-3 flex flex-row items-center justify-between bg-gray-100'>
                <div className='flex items-center'>
                    <div className='h-4 w-4 bg-gray-300 rounded-full'></div>
                    <div className='h-4 bg-gray-300 rounded w-16 ml-2'></div>
                </div>
                <div className='flex items-center'>
                    <div className='h-4 w-4 bg-gray-300 rounded-full'></div>
                    <div className='h-4 bg-gray-300 rounded w-16 ml-2'></div>
                </div>
            </div>
        </div>
    );
};

export default PostCardSkeleton;